import { initializeApp } from "firebase/app"
import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore/lite"
import { getAuth } from "firebase/auth"
import { QueryClient } from '@tanstack/react-query'
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
}

const app = initializeApp(firebaseConfig)


export const db = getFirestore(app)

export const auth = getAuth(app)

export const storage = getStorage(app)

export async function orders() {
    const ordersCollectionRef = collection(db, 'Orders')
    const dataSnapshot = await getDocs(ordersCollectionRef)
    return dataSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
}

export async function products() {
    const productsCollectionRef = collection(db, 'Products')
    const dataSnapshot = await getDocs(productsCollectionRef)
    return dataSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
}

export async function promotions() {
    const promotionsCollectionRef = collection(db, 'Promotions')
    const dataSnapshot = await getDocs(promotionsCollectionRef)
    return dataSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
}

export async function user(id) {
    const userDocRef = doc(db, 'Users', id)
    const userSnapshot = await getDoc(userDocRef)
    return { ...userSnapshot.data(), id: userSnapshot.id }
}

export const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity, gcTime: Infinity } } })