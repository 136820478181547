import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Await, Form, defer, useActionData, useLoaderData, useNavigation } from 'react-router-dom'
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore/lite'
import { db, promotions, queryClient } from '../Db/FirebaseConfig'
import Loading, { Submitting } from '../Components/Loading/Loading'
import Dialog from '../Components/Dialog/Dialog'
import { AiFillPlusCircle } from 'react-icons/ai'
import { IoClose } from 'react-icons/io5'
import './Promotions.css'

let actionReturn = 0
export async function action({ request }) {
    const formData = await request.formData()
    const intent = formData.get('intent')
    if (intent === 'new') {
        const promotionData = {
            code: formData.get('code').toUpperCase(), discount: Number(formData.get('discount')),
            validity: (new Date(formData.get('validity'))), timeStamp: (new Date())
        }
        const promotionsCollectionRef = collection(db, 'Promotions')
        await addDoc(promotionsCollectionRef, promotionData)
    }
    else if (intent === 'update') {
        const promotionData = {
            code: formData.get('code').toUpperCase(), discount: Number(formData.get('discount')),
            validity: (new Date(formData.get('validity')))
        }
        const promotionDocRef = doc(db, 'Promotions', formData.get('id'))
        await updateDoc(promotionDocRef, promotionData)
    }
    else if (intent === 'delete') {
        const promotionDocRef = doc(db, 'Promotions', formData.get('id'))
        await deleteDoc(promotionDocRef)
    }
    await queryClient.invalidateQueries({ queryKey: ['promotionsData'] })
    return ++actionReturn
}

export function loader() {
    return defer({
        dataSet: queryClient.fetchQuery({
            queryKey: ['promotionsData'],
            queryFn: () => promotions().then(res => res.sort((a, b) => b.timeStamp - a.timeStamp))
        })
    })
}

export default function Promotions() {

    const { dataSet } = useLoaderData()

    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataSet}>
                {dataSetLoaded => <Content promotionsData={dataSetLoaded} />}
            </Await>
        </Suspense>
    )
}

function Content({ promotionsData }) {

    const ref = useRef([])
    const deleteRef = useRef()
    const { state, formData } = useNavigation()
    const [promoIdx, setPromoIdx] = useState(-1)
    const actionData = useActionData()

    useEffect(() => {
        if (actionData) {
            closePromo()
            deleteRef.current.classList.remove('show')
        }
    }, [actionData])

    function addPromo() {
        setPromoIdx(-1)
        ref.current.forEach((el, idx) => {
            if (idx > 0) el.value = ''
        })
        ref.current[0].classList.add('show')
    }

    function editPromo(idx) {
        setPromoIdx(idx)
        const editPromotion = promotionsData[idx]
        ref.current.forEach((el, idx) => {
            switch (idx) {
                case 1:
                    el.value = editPromotion.id
                    break
                case 2:
                    el.value = editPromotion.code
                    break
                case 3:
                    el.value = editPromotion.discount
                    break
                case 4:
                    el.value = editPromotion.validity.toDate().toLocaleDateString('en-CA')
                    break
            }
        })
        ref.current[0].classList.add('show')
    }

    function deletePromo() {
        deleteRef.current.classList.add('show')
    }

    function closePromo() {
        ref.current[0].classList.remove('show')
    }

    const promotionElems = promotionsData.map((promotion, idx) =>
        <PromotionCard
            key={idx}
            idx={idx}
            code={promotion.code}
            discount={promotion.discount}
            validity={promotion.validity}
            editPromo={editPromo} />)

    return (
        <>
            <div className='promos-card-container'>
                <h1>Promotions</h1>
                <div className='add-new' onClick={addPromo}><AiFillPlusCircle /></div>
                {promotionElems.length > 0 ? promotionElems : <h2>No Promotions</h2>}
            </div>
            <div className='dialog-wrapper'>
                <div className='promos-form-wrapper' ref={el => ref.current[0] = el}>
                    <Form className={`promos-form${state === 'submitting' ? ' disable' : ''}`} method='post'
                        replace preventScrollReset>
                        <h2>Promotion</h2>
                        <span className='promos-close' onClick={closePromo}><IoClose /></span>
                        <input type='text' name='id' readOnly ref={el => ref.current[1] = el} />
                        <input type='text' name='code' placeholder='Promo Code' minLength={6} maxLength={6} required
                            ref={el => ref.current[2] = el} />
                        <input type="number" name='discount' placeholder='Discount (%)' min={1} max={100} required
                            ref={el => ref.current[3] = el} />
                        <input type='date' name='validity' min={(new Date()).toLocaleDateString('en-CA')} required
                            ref={el => ref.current[4] = el} />
                        {promoIdx < 0 ? <button type="submit" name='intent' value='new'>
                            {state === 'submitting' ? <>Saving<Submitting /></> : 'Save Promotion'}
                        </button> : <div className='delete-update-div'>
                            <button type="button" className="delete" onClick={deletePromo}>Delete</button>
                            <button type="submit" name='intent' value='update'>
                                {state === 'submitting' && formData?.get('intent') === 'update' ? <>Updating<Submitting /></> : 'Update'}
                            </button>
                        </div>}
                    </Form>
                </div>
            </div>
            <Dialog refEl={deleteRef} closeRef={deleteRef.current} title='Delete Promotion?' submiting='Deleting'
                value={promotionsData[promoIdx]?.id || ''} intent='delete' />
        </>
    )
}

function PromotionCard({ code, discount, validity, idx, editPromo }) {

    const options = { day: 'numeric', month: 'short', year: 'numeric' }

    return (
        <div className='promotion-card' onClick={() => editPromo(idx)}>
            <h2>{code}</h2>
            <p><strong>Discount:</strong> -{discount}%</p>
            <p><strong>Till:</strong> {validity.toDate().toLocaleDateString('en-IN', options)}</p>
        </div>
    )
}