import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import Nav, { loader as NavLoader } from './Nav/Nav'
import Home, { loader as HomeLoader } from './Home/Home'
import OrdersData, { loader as OrdersLoader } from './Orders/OrdersData/OrdersData'
import OrdersList, { action as OrderSearchAction } from './Orders/OrdersList/OrdersList'
import OrderDetails, { action as OrderAction } from './Orders/OrderDetails/OrderDetails'
import Products, { loader as ProductsLoader, action as ProductsAction } from './Products/Products'
import Promotions, { loader as PromotionsLoader, action as PromotionsAction } from './Promotions/Promotions'
import Login, { loader as LoginLoader, action as LoginAction } from './Login/Login'
import NotFound from './NotFoundPage/NotFoundPage'
import Error from './Components/Error/Error'

export default function App() {

  const router = createBrowserRouter(createRoutesFromElements(
    <>
      <Route path='/' element={<Nav />} loader={NavLoader} errorElement={<Error />}>
        <Route index element={<Home />} loader={HomeLoader} errorElement={<Error />} />
        <Route path='orders' element={<OrdersData />} loader={OrdersLoader} action={OrderAction} errorElement={<Error />}>
          <Route index element={<OrdersList />} action={OrderSearchAction} />
          <Route path=':orderId' element={<OrderDetails />} />
        </Route>
        <Route path='products' element={<Products />} loader={ProductsLoader} action={ProductsAction}
          errorElement={<Error />} />
        <Route path='promotions' element={<Promotions />} loader={PromotionsLoader} action={PromotionsAction}
          errorElement={<Error />} />
      </Route>
      <Route path='/login' element={<Login />} loader={LoginLoader} action={LoginAction} errorElement={<Error />} />
      <Route path='*' element={<NotFound />} errorElement={<Error />} />
    </>
  ))

  return (
    <RouterProvider router={router} />
  )
}