import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Await, Form, defer, useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router-dom'
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore/lite'
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { db, products, queryClient, storage } from '../Db/FirebaseConfig'
import Loading, { Submitting } from '../Components/Loading/Loading'
import Filter from '../Components/Filter/Filter'
import ProductCard from '../Components/ProductCard/ProductCard'
import Dialog from '../Components/Dialog/Dialog'
import { AiOutlineMinus, AiOutlinePlus, AiFillPlusCircle } from "react-icons/ai"
import { IoClose } from 'react-icons/io5'
import './Products.css'

let actionReturn = 0
export async function action({ request }) {
    const formData = await request.formData()
    const intent = formData.get('intent')

    if (intent === 'delete') {
        const id = formData.get('id')
        const productsData = await queryClient.fetchQuery({
            queryKey: ['productsData'], queryFn: () => products()
        })
        const images = productsData.find(product => product.id === id).img
        await Promise.all(images.map(async image => await deleteImages(productsData, id, image)))
        const productDocRef = doc(db, 'Products', id)
        await deleteDoc(productDocRef)
    }

    else if (intent === 'new' || intent === 'update') {
        const title = formData.get('title')
        const category = formData.get('category')
        const description = formData.get('description')
        const size = `${formData.get('size').trim()}ml`
        const sizes = [size, ...formData.get('sizes').replaceAll(' ', '').split(',').map(e => e + 'ml')]
        const productids = Object.fromEntries(sizes.map(item => [item, title.replaceAll(' ', '').toLowerCase() + item]))
        let images = Array.from(formData.keys()).filter(key => key.startsWith('image')).map(key => formData.get(key))
        const price = Number(formData.get('price'))
        const stock = Number(formData.get('stock'))
        if (intent === 'new') {
            images = await Promise.all(images.map(async image => await uploadImages(category.replaceAll(' ', '').toLowerCase(), image)))
            const docData = { Sno: Number(formData.get('productId')), title, category, description, sizes, productids, price, stock, rating: 0, review: 0, img: images }
            const productDocRef = doc(db, 'Products', productids[size])
            await setDoc(productDocRef, docData)
        }
        else {
            const id = formData.get('productId')
            const currentImgs = JSON.parse(formData.get('imgs'))
            const removeImgs = currentImgs.filter(image => !images.includes(image))
            if (removeImgs.length > 0 || images.length !== currentImgs.length) {
                if (removeImgs.length > 0) {
                    const productsData = await queryClient.fetchQuery({
                        queryKey: ['productsData'], queryFn: () => products()
                    })
                    await Promise.all(removeImgs.map(async image => await deleteImages(productsData, id, image)))
                }
                images = await Promise.all(images.map(async image => {
                    if (typeof image === 'string') return image
                    else return await uploadImages(category.replaceAll(' ', '').toLowerCase(), image)
                }))
            }
            const docData = { title, category, description, sizes, productids, price, stock, img: images }
            const productDocRef = doc(db, 'Products', id)
            await updateDoc(productDocRef, docData)
        }
    }

    async function uploadImages(category, file) {
        const existingFiles = await listAll(ref(storage, `Product-Images/${category}`))
        const existingFile = existingFiles.items.find(item => item.name === file.name)
        if (existingFile) return await getDownloadURL(existingFile)
        const storageRef = ref(storage, `Product-Images/${category}/${file.name}`)
        const metaData = { cacheControl: 'public, max-age=31536000', contentType: file.type }
        await uploadBytes(storageRef, file, metaData)
        return await getDownloadURL(storageRef)
    }

    async function deleteImages(products, id, url) {
        const isReused = products.some(product => product.id !== id && product.img.includes(url))
        if (!isReused) await deleteObject(ref(storage, url))
    }

    await queryClient.invalidateQueries({ queryKey: ['productsData'] })
    return ++actionReturn
}

export function loader() {
    return defer({
        dataSet: queryClient.fetchQuery({
            queryKey: ['productsData'], queryFn: () => products()
        }).then(res => res.sort((a, b) => a.Sno - b.Sno))
    })
}

export default function Products() {

    const dataSetPromise = useLoaderData()

    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataSetPromise.dataSet}>
                {dataSetLoaded => <Content dataSetLoaded={dataSetLoaded} />}
            </Await>
        </Suspense>
    )
}

function Content({ dataSetLoaded }) {

    const ref = useRef([])
    const deleteRef = useRef()
    const [selected, setSelected] = useState('')
    const [edit, setEdit] = useState(false)
    const [stock, setStock] = useState('')
    const [images, setImages] = useState([])
    const { state, formData } = useNavigation()
    const actionData = useActionData()
    const submit = useSubmit()

    useEffect(() => {
        if (actionData) {
            ref.current[0].classList.remove('show')
            deleteRef.current.classList.remove('show')
        }
    }, [actionData])

    function addProduct() {
        setEdit(false)
        setStock('')
        setImages([])
        ref.current.forEach((el, idx) => {
            if (idx === 1) el.value = dataSetLoaded.length
            else if (idx > 1) el.value = ''
        })
        ref.current[0].classList.add('show')
    }

    function editProduct(idx) {
        const productInfo = dataSetLoaded[idx]
        setEdit(true)
        setStock(Number(productInfo.stock))
        setImages(productInfo.img)
        ref.current.forEach((el, idx) => {
            switch (idx) {
                case 1:
                    el.value = productInfo.id
                    break
                case 2:
                    el.value = productInfo.title
                    break
                case 3:
                    el.value = productInfo.category
                    break
                case 4:
                    el.value = productInfo.description
                    break
                case 5:
                    el.value = Number(productInfo.sizes[0].slice(0, -2))
                    break
                case 6:
                    el.value = productInfo.sizes.slice(1).map(e => e.slice(0, -2)).join(', ')
                    break
                case 7:
                    el.value = Number(productInfo.price)
                    break
                case 8:
                    el.value = JSON.stringify(productInfo.img)
                    break
            }
        })
        ref.current[0].classList.add('show')
    }

    function closeDialog() {
        ref.current[0].classList.remove('show')
    }

    function deleteDialog() {
        deleteRef.current.classList.add('show')
    }

    let filteredProducts = dataSetLoaded

    if (selected) filteredProducts = dataSetLoaded.filter(({ category }) => category === selected)

    const products = filteredProducts.map((product, idx) => <ProductCard key={product.id} img={product.img[0]}
        title={product.title} price={product.price} size={product.sizes[0]} rating={product.rating}
        review={product.review} clickHandler={() => editProduct(idx)} stock={product.stock} />)

    function handleStock(event) {
        if (event.target.id === 'minus') setStock(prevStock => Number(prevStock - 1))
        else if (event.target.id === 'plus') setStock(prevStock => Number(prevStock + 1))
        else setStock(Number(event.target.value))
    }

    function addImg(event) {
        const file = event.target.files[0]
        if (file) setImages(prevImages => [...prevImages, file])
        event.target.value = ''
    }

    function removeImg(idx) {
        setImages(prevImages => prevImages.filter((_, index) => index !== idx))
    }

    function handleSubmit(event) {
        event.preventDefault()
        const formData = new FormData(event.target)
        formData.append('intent', edit ? 'update' : 'new')
        images.forEach((image, idx) => formData.append(`image${idx}`, image))
        submit(formData, { method: 'POST', replace: true, preventScrollReset: true, action, encType: 'multipart/form-data' })
    }

    return (
        <>
            <div className='products-wrapper'>
                <Filter productList={dataSetLoaded} selected={selected} setSelected={setSelected} />
                <div className="products-container">
                    <div className='add-product' onClick={addProduct}>
                        <AiFillPlusCircle />
                    </div>
                    {products}
                </div>
            </div>
            <div className='dialog-wrapper'>
                <div className='product-dialog' ref={el => ref.current[0] = el}>
                    <Form method='post' replace preventScrollReset onSubmit={handleSubmit}
                        className={`product-form${state === 'submitting' ? ' disable' : ''}`}>
                        <h2>{edit ? 'Edit Product' : 'New Product'}</h2>
                        <span className='dialog-close-icon' onClick={closeDialog}><IoClose /></span>
                        <input type='text' name='productId' ref={el => ref.current[1] = el} readOnly />
                        <input type='text' name='title' placeholder='Title' ref={el => ref.current[2] = el} required />
                        <input type='text' name='category' placeholder='Category' ref={el => ref.current[3] = el}
                            required />
                        <textarea rows={3} name='description' placeholder='Product Description'
                            ref={el => ref.current[4] = el} required />
                        <input type='number' name='size' placeholder='Size (ml)' ref={el => ref.current[5] = el}
                            required />
                        <input type='text' name='sizes' placeholder='Other Sizes (ml)' pattern="^\s*\d+(\s*,\s*\d+)*\s*$"
                            ref={el => ref.current[6] = el} />
                        <input type='number' name='price' placeholder='Price (रू)' ref={el => ref.current[7] = el}
                            required />
                        <div className='stock-div'>
                            <span id='minus' className='stock-icon-span' onClick={handleStock}>
                                <AiOutlineMinus className='stock-icon' />
                            </span>
                            <input type='number' name='stock' placeholder='Stock' min={0} value={stock}
                                onChange={handleStock} required />
                            <span id='plus' className='stock-icon-span' onClick={handleStock}>
                                <AiOutlinePlus className='stock-icon' />
                            </span>
                        </div>
                        <div className='image-drop'>
                            <input type='text' name='imgs' ref={el => ref.current[8] = el} readOnly />
                            {images.map((image, idx) => (<div className='image-div' key={idx}>
                                <img src={image instanceof File ? URL.createObjectURL(image) : image} alt='product-image' />
                                <span onClick={() => removeImg(idx)}><IoClose /></span>
                            </div>))}
                            {images.length < 5 && <label>
                                <input type='file' name='img' accept='image/webp' onChange={addImg}
                                    required={images.length < 1} />
                                <AiOutlinePlus />
                            </label>}
                        </div>
                        {edit ? <><button className='delete' type='button' onClick={deleteDialog}>Delete</button>
                            <button type='submit'>
                                {state === 'submitting' && formData?.get('intent') === 'update' ? <>Updating<Submitting /></> : 'Update'}
                            </button></> : <button className='new' type='submit'>
                            {state === 'submitting' && formData?.get('intent') === 'new' ? <>Saving<Submitting /></> : 'Save Product'}
                        </button>}
                    </Form>
                </div>
            </div >
            <Dialog refEl={deleteRef} closeRef={deleteRef.current} title='Delete Product?' submiting='Deleting'
                value={ref.current[1]?.value || ''} intent='delete' />
        </>
    )
}