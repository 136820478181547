import React, { Suspense } from 'react'
import { Await, defer, Link, useLoaderData } from 'react-router-dom'
import { orders, products, queryClient } from '../Db/FirebaseConfig'
import Loading from '../Components/Loading/Loading'
import Linechart from './Linechart'
import Piechart from './Piechart'
import { TbCurrencyRupeeNepalese } from "react-icons/tb"
import { GiProgression } from "react-icons/gi"
import { LuUsers } from "react-icons/lu"
import { BsBoxSeam } from "react-icons/bs"
import './Home.css'

export function loader() {
    return defer({
        dataSet: Promise.all([queryClient.fetchQuery({
            queryKey: ['ordersData'], queryFn: () => orders()
        }).then(res => res.filter(order => order.status === 'delivered')),
        queryClient.fetchQuery({ queryKey: ['productsData'], queryFn: () => products() })])
    })
}

export default function Home() {

    const { dataSet } = useLoaderData()

    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataSet}>
                {([ordersData, productsData]) => <Content ordersData={ordersData} productsData={productsData} />}
            </Await>
        </Suspense>
    )
}

function Content({ ordersData, productsData }) {

    const style = { notation: "compact", compactDisplay: "short", maximumFractionDigits: 2 }
    const formatter = new Intl.NumberFormat("en-IN", style)
    const soldProducts = ordersData.flatMap(order => order.products)

    const revenue = ordersData.reduce((acc, { totalPrice }) => acc += totalPrice, 0)
    const sales = soldProducts.reduce((acc, { quantity }) => acc += quantity, 0)
    const customers = new Set(ordersData.map(order => order.userId)).size
    const totalProducts = productsData.reduce((acc, { stock }) => acc += stock, 0)

    const profit = ordersData.reduce((acc, { timeStamp, totalPrice }) => {
        const options = { day: 'numeric', month: 'numeric', year: '2-digit' }
        const dateString = timeStamp.toDate().toLocaleDateString('en-IN', options)
        const existingEntry = acc.find(item => item.Date === dateString)
        if (existingEntry) existingEntry.Revenue += totalPrice
        else acc.push({ Date: dateString, Revenue: totalPrice })
        return acc
    }, [])

    const categories = productsData.reduce((acc, { category, id }) => {
        if (!acc[category]) acc[category] = []
        acc[category].push(id)
        return acc
    }, {})

    const productIds = soldProducts.reduce((acc, { productId, title, size, quantity }) => {
        if (!acc[productId]) acc[productId] = { quantity, title, size }
        else acc[productId] = { quantity: acc[productId].quantity + quantity, title, size }
        return acc
    }, {})

    return (
        <div className='home-container'>
            <div className='insights-container'>
                <Link to='orders' className='insights-div'>
                    <span><TbCurrencyRupeeNepalese /></span>
                    <h3>Revenue</h3>
                    <h2>रू&nbsp;{formatter.format(revenue)}</h2>
                </Link>
                <Link to='orders' className='insights-div'>
                    <span><GiProgression /></span>
                    <h3>Sales</h3>
                    <h2>↥&nbsp;{formatter.format(sales)}</h2>
                </Link>
                <Link to='orders' className='insights-div'>
                    <span><LuUsers /></span>
                    <h3>Buyers</h3>
                    <h2>+&nbsp;{formatter.format(customers)}</h2>
                </Link>
                <Link to='products' className='insights-div'>
                    <span><BsBoxSeam /></span>
                    <h3>In&nbsp;Stock</h3>
                    <h2>{formatter.format(totalProducts)}</h2>
                </Link>
            </div>
            <div className='chart-container'>
                <Linechart data={profit} />
                <Piechart products={productsData} categories={categories} productIds={productIds} />
            </div>
        </div>
    )
}