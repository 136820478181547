import React, { useRef } from 'react'
import { Form, Link, redirect, useNavigation, useOutletContext, useSubmit } from 'react-router-dom'
import DatePicker from '../../Components/DatePicker/DatePicker'
import { IoSearch } from "react-icons/io5"
import { BsHash } from "react-icons/bs"
import './OrdersList.css'

export async function action({ request }) {
    const formData = await request.formData()
    throw redirect(`/orders/${formData.get('orderId').toUpperCase()}`)
}

export default function OrdersList() {

    const { orders, range, setRange } = useOutletContext()
    const ref = useRef()
    const { state } = useNavigation()
    const submit = useSubmit()

    function searchOrder() {
        if (!ref.current.checkValidity()) ref.current.reportValidity()
        else submit(ref.current)
    }

    return (
        <div className='orders-container'>
            <div className='topbar'>
                <Form method='post' replace preventScrollReset ref={ref}
                    className={`searchbox${state === 'submitting' ? ' disable' : ''}`}>
                    <BsHash className='hash-icon' />
                    <input type='text' name='orderId' minLength={11} maxLength={11} placeholder='Order ID' required />
                    <IoSearch className='search-icon' onClick={searchOrder} />
                </Form>
                <DatePicker range={range} setRange={setRange} />
            </div>
            <Content orders={orders} range={range} />
        </div>
    )
}

function Content({ orders, range }) {

    if (orders.length === 0) {
        return (
            <h1>No Order History</h1>
        )
    }

    const ordersInRange = orders.filter(({ timeStamp }) => {
        const orderDate = timeStamp.toDate().setHours(0, 0, 0, 0)
        const startDate = range[0].startDate.setHours(0, 0, 0, 0)
        const endDate = range[0].endDate.setHours(0, 0, 0, 0)
        return orderDate >= startDate && orderDate <= endDate
    }).sort((a, b) => b.timeStamp - a.timeStamp)

    let months = []
    ordersInRange.forEach(({ month }) => {
        if (!months.includes(month)) months.push(month)
    })

    const orderElems = months.map((month, idx) => {
        const currentMonthOrders = ordersInRange.filter(order => order.month === month)
        const currentMonthOrdersElems = currentMonthOrders.map((order, idx) => <OrderCard key={idx} orderData={order} />)
        return (
            <div key={idx} className='orders-cards-container'>
                <h2>{month}</h2>
                {currentMonthOrdersElems}
            </div>
        )
    })

    return orderElems.length > 0 ?
        (<div className='orders-cards-wrapper'>
            {orderElems}
        </div>) : (<h1>No Orders</h1>)
}

function OrderCard({ orderData }) {

    const productNum = orderData.products.length > 1 ? `, +${orderData.products.length - 1}` : ''
    const options = { day: 'numeric', month: 'short', year: 'numeric' }

    return (
        <Link to={`/orders/${orderData.orderId}`} className='order-card'>
            <img src={orderData.products[0].productImg} alt={orderData.products[0].title} />
            <h4>#{orderData.orderId}</h4>
            <h4>{`${orderData.products[0].title} ${orderData.products[0].size} x ${orderData.products[0].quantity}${productNum}`}</h4>
            <p>{orderData.timeStamp.toDate().toLocaleDateString('en-IN', options)}</p>
            <p>रू&nbsp;{(orderData.totalPrice).toFixed(2)}</p>
            <h4>{orderData.status}</h4>
        </Link>
    )
}