import React from 'react'
import { AiFillStar } from "react-icons/ai"
import './ProductCard.css'

export default function ProductCard(props) {
    return (
        <div className='product-card' onClick={props.clickHandler}>
            <div className="card-img"><img src={props.img} alt={props.title} /></div>
            <h3>{props.title}</h3>
            <div className="info">
                <p>रू&nbsp;{props.price}</p>
                <p>{props.size}</p>
            </div>
            <div className="review-rating">
                <div className="rating">
                    <AiFillStar className='star-icon' />
                    {props.rating}
                </div>
                <p>{props.review}</p>
            </div>
            <div className="product-card-stock">{props.stock}</div>
        </div>
    )
}
