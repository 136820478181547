import React from 'react'
import { useRouteError } from 'react-router-dom'

export default function Error() {

    const error = useRouteError()
    console.error(error)

    return (
        <div className='notfound-div error-div'>
            <h1>Error: {error.message || error.data}</h1>
            <pre>{error.status || error.code} - {error.statusText}</pre>
            <button onClick={() => window.location.reload()}>Refresh Page</button>
        </div>
    )
}