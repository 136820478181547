import { addDoc, collection } from "firebase/firestore/lite"
import { db } from "../Db/FirebaseConfig"

export default async function Email(email, name, orderId, products, total, address, mode, orderDate, deliveryDate) {
    const subject = `Your Oats By Nush Order Number ${orderId} Confirmation 🌾`
    const orders = products.map((product, idx) => (
        `<li>
            <p style="margin: 0; padding: 0;">Product ${idx + 1}: ${product.title} ${product.size}</p>
            <p style="margin: 0; padding: 0;">Quantity: ${product.quantity}</p>
            <p style="margin: 0 0 15px 0; padding: 0;">Price: रू ${(product.price).toFixed(2)}</p>
        </li>`
    ))
    const html = `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="utf-8" />
    </head>
    <body style="font-family: Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 1.75;">
    <p style="margin: 0; padding: 0;">Dear ${name},</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">We're excited to inform you that your order with Oats By Nush has been successfully confirmed! 🎉 Thank you for choosing us for your oat-based needs.</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Here's a quick overview of your order:</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Order Number: ${orderId}</p>
    <p style="margin: 0; padding: 0;">Date of Order: ${orderDate}</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Order Summary:</p>
    <br style="line-height: 0.25;" />
    <ul style="margin: 0; padding: 0 0 0 15px;">${orders.join('')}</ul>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Total Amount: रू ${total.toFixed(2)}</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Shipping Address:</p>
    <p style="margin: 0; padding: 0;"><strong>${address.title}. ${address.fullName}</strong></p>
    <p style="margin: 0; padding: 0;">${address.street}</p>
    <p style="margin: 0; padding: 0;">${address.city}, ${address.district}</p>
    <p style="margin: 0; padding: 0;">Phone number: ${address.phone}</p>
    ${address.email ? `<p style="margin: 0; padding: 0;">Email: ${address.email}</p>` : ''}
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Payment Method: ${mode}</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">We're currently preparing your order with care, and it will be dispatched for delivery on ${deliveryDate}.</p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">If you have any questions or need further assistance, feel free to reach out to us at <a href="mailto:info@oatsbynush.com" target="_blank">info@oatsbynush.com</a></p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Thank you for supporting our mission of providing nutritious and sustainable oat-based products. We appreciate your trust in Oats By Nush!<p>
    <br style="line-height: 0.25;" />
    <p style="margin: 0; padding: 0;">Warm regards,</p>
    <p style="margin: 0; padding: 0;">The Oats By Nush Team</p>
    </body>
    </html>`
    const emailsCollectionRef = collection(db, 'Emails')
    await addDoc(emailsCollectionRef, {
        to: [email], bcc: ['info@oatsbynush.com'], message: { subject: subject, html: html }
    })
}