import React, { useState } from 'react'
import { Form, redirect, useActionData, useNavigation } from 'react-router-dom'
import { browserLocalPersistence, browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../Db/FirebaseConfig'
import { CurrentUser } from '../Utils/HandleUser'
import { IoMailOutline, IoLockClosedOutline, IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import { Submitting } from '../Components/Loading/Loading'
import './Login.css'

export async function action({ request }) {
    const formData = await request.formData()
    try {
        const email = formData.get('email')
        if (email !== process.env.REACT_APP_AUTH_EMAIL) {
            return 'Incorrect email or password'
        }
        if (!formData.get('remember')) {
            await setPersistence(auth, browserSessionPersistence)
        }
        else {
            await setPersistence(auth, browserLocalPersistence)
        }
        await signInWithEmailAndPassword(auth, email, formData.get('password'))
    }
    catch (err) {
        if (err.code === 'auth/invalid-credential') {
            return 'Incorrect email or password'
        }
        else {
            console.error(err)
            alert(err.message)
        }
    }
    throw redirect('/')
}

export async function loader() {
    const user = await CurrentUser()
    if (user) throw redirect('/')
    return null
}

export default function Login() {

    const { state } = useNavigation()
    const actionData = useActionData()
    const [passShow, setPassShow] = useState(false)

    return (
        <div className='login-container'>
            <Form className={state === 'submitting' ? 'disable' : ''}
                method='post' replace preventScrollReset>
                <h1>Login</h1>
                {actionData && state === 'idle' && <h4>{actionData}</h4>}
                <label className='input-box'>
                    <IoMailOutline className="icon" />
                    <input type="email" name="email" placeholder='Email' required />
                </label>
                <label className='input-box'>
                    <IoLockClosedOutline className="icon" />
                    {passShow ? <IoEyeOffOutline className='pass-icon' onClick={() => setPassShow(false)} /> :
                        <IoEyeOutline className='pass-icon' onClick={() => setPassShow(true)} />}
                    <input type={passShow ? "text" : "password"} name="password" placeholder='Password'
                        minLength={8} required />
                </label>
                <label className="login-remember">
                    <input type="checkbox" name="remember" />
                    <p>Remember me</p>
                </label>
                <button type='submit'>{state === 'submitting' ? <>Logging in<Submitting /></> : 'Log in'}</button>
            </Form>
        </div>
    )
}
