import React, { useEffect, useLayoutEffect, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { queryClient } from '../Db/FirebaseConfig'
import DropDown from '../Components/DropDown/DropDown'

export default function Linechart({ data }) {

    const stateData = queryClient.getQueryData('days')
    const daysArr = ['7 Days', '30 Days', '90 Days', '365 Days']
    const [days, setDays] = useState(daysArr[0])

    const day = new Date(new Date().getTime() - (Number(days.replace(/\D/g, '')) * 86400000))

    useLayoutEffect(() => { if (stateData) setDays(stateData) }, [])
    useEffect(() => { queryClient.setQueryData('days', days) }, [days])

    const filteredData = data.filter(item => {
        const [date, month, year] = item.Date.split('/').map(Number)
        return new Date(year + 2000, month - 1, date).setHours(0, 0, 0, 0) >= day.setHours(0, 0, 0, 0)
    }).sort((a, b) => {
        const [aDate, aMonth, aYear] = a.Date.split('/').map(Number)
        const aDay = new Date(aYear + 2000, aMonth - 1, aDate).setHours(0, 0, 0, 0)
        const [bDate, bMonth, bYear] = b.Date.split('/').map(Number)
        const bDay = new Date(bYear + 2000, bMonth - 1, bDate).setHours(0, 0, 0, 0)
        return aDay - bDay
    })

    return (
        <div className='linechart-container'>
            <div className='top-bar'>
                <h2>Transactions</h2>
                <DropDown items={daysArr} selected={days} setSelected={setDays} name='days' />
            </div>
            {filteredData.length > 0 ? <ResponsiveContainer width='100%' style={{ flexGrow: 1 }}>
                <LineChart data={filteredData} margin={{ right: 10, top: 10, left: -20 }}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='Date' stroke='#000' />
                    <YAxis dataKey='Revenue' stroke='#000' />
                    <Tooltip content={<CustomTooltip />} />
                    <Line type='monotone' stroke='#000' activeDot={{ r: 8 }} dataKey='Revenue' />
                </LineChart>
            </ResponsiveContainer> : <div className='no-transactions'>No Transactions</div>}
        </div>
    )
}

function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
        const [date, month, year] = payload[0].payload?.Date.split('/').map(Number)
        const options = { day: 'numeric', month: 'short', year: 'numeric' }
        return (
            <div className="tooltip">
                <p>{new Date(year + 2000, month - 1, date).toLocaleDateString('en-IN', options)}</p>
                <p>Revenue&nbsp;:&nbsp;रू&nbsp;{payload[0]?.payload?.Revenue}</p>
            </div>
        )
    }
    return null
}