import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react'
import { Await, Outlet, defer, useLoaderData } from 'react-router-dom'
import { orders, queryClient } from '../../Db/FirebaseConfig'
import Loading from '../../Components/Loading/Loading'

export function loader() {
    return defer({
        dataSet: queryClient.fetchQuery({ queryKey: ['ordersData'], queryFn: () => orders() })
    })
}

export default function OrdersData() {

    const { dataSet } = useLoaderData()

    const stateData = queryClient.getQueryData('range')
    const currentDate = new Date()
    const [range, setRange] = useState([{
        startDate: new Date(currentDate.getTime() - 604800000),
        endDate: currentDate,
        key: 'selection'
    }])

    useLayoutEffect(() => { if (stateData) setRange(stateData) }, [])
    useEffect(() => { queryClient.setQueryData('range', range) }, [range])

    return (
        <Suspense fallback={<Loading />}>
            <Await resolve={dataSet}>
                {dataSetLoaded => <Outlet context={{ orders: dataSetLoaded, range, setRange }} />}
            </Await>
        </Suspense>
    )
}
