import React, { useEffect, useRef, useState } from 'react'
import { Form, Link, redirect, useNavigation, useOutletContext, useParams, useSubmit } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore/lite'
import { db, queryClient, user } from '../../Db/FirebaseConfig'
import { RxQuestionMarkCircled } from "react-icons/rx"
import { IoArrowBack } from "react-icons/io5"
import { Submitting } from '../../Components/Loading/Loading'
import DropDown from '../../Components/DropDown/DropDown'
import Email from '../../Utils/Email'
import './OrderDetails.css'

export async function action({ request }) {
    const formData = await request.formData()
    const intent = formData.get('intent')
    if (intent === 'status') {
        const orderDocRef = doc(db, 'Orders', formData.get('docId'))
        await updateDoc(orderDocRef, { status: formData.get('status') })
        await queryClient.invalidateQueries({ queryKey: ['ordersData'] })
        if (formData.get('status') === 'delivered') {
            const userData = await user(formData.get('userId'))
            if (!userData.expiresAt && !userData.isVerified) {
                const userDocRef = doc(db, 'Users', formData.get('userId'))
                await updateDoc(userDocRef, { isVerified: true })
            }
        }
        throw redirect(`/orders/${formData.get('orderId')}`)
    }
    else if (intent === 'email') {
        const orderData = JSON.parse(formData.get('orderData'))
        const options = { day: 'numeric', month: 'long', year: 'numeric' }
        const milliSeconds = orderData.timeStamp.seconds * 1000 + orderData.timeStamp.nanoseconds / 1000000
        const orderDate = (new Date(milliSeconds)).toLocaleDateString('en-IN', options)
        const deliveryDate = (new Date(formData.get('delivery-date'))).toLocaleDateString('en-IN', options)
        await Email(orderData.email, orderData.address.fullName, orderData.orderId, orderData.products, orderData.totalPrice, orderData.address, orderData.paymentMode, orderDate, deliveryDate)
        const orderDocRef = doc(db, 'Orders', orderData.id)
        await updateDoc(orderDocRef, { emailed: true })
        await queryClient.invalidateQueries({ queryKey: ['ordersData'] })
        throw redirect(`/orders/${orderData.orderId}`)
    }
}

export default function OrderDetails() {

    const { state, formData } = useNavigation()
    const { orderId } = useParams()
    const { orders } = useOutletContext()

    const orderData = orders.find(order => order?.orderId === orderId)

    const productsElems = orderData?.products.map((product, idx) => <ProductCard key={idx} productData={product} />)

    const options = { day: 'numeric', month: 'short', year: 'numeric' }

    const formRef = useRef()
    const submit = useSubmit()
    const [selected, setSelected] = useState(orderData?.status)
    const items = ['placed', 'transit', 'delivered']

    useEffect(() => {
        if (selected !== orderData?.status) submit(formRef.current)
    }, [selected])

    return (
        <div className='order-details-wrapper'>
            <div className='order-details-title-container'>
                <Link to='/orders' className="orders-back-icon"><IoArrowBack /></Link>
                <h1>Order Details</h1>
            </div>
            <div className='order-details-container'>
                {productsElems ? <>
                    <h3>Order: #{orderData.orderId}</h3>
                    <div className='order-items-div'>
                        <div className={`order-status-container ${state === 'submitting' ? 'disable' : ''}`}>
                            <p><strong>Date:</strong> {orderData.timeStamp.toDate().toLocaleDateString('en-IN', options)}</p>
                            <DropDown items={items} selected={selected} setSelected={setSelected} name='status' />
                            <Form action='/orders' method='post' replace ref={formRef} preventScrollReset>
                                <input type='text' name='docId' value={orderData.id} readOnly />
                                <input type='text' name='orderId' value={orderData.orderId} readOnly />
                                <input type='text' name='userId' value={orderData.userId} readOnly />
                                <input type='text' name='status' value={selected} readOnly />
                                <input type='text' name='intent' value='status' readOnly />
                            </Form>
                        </div>
                        <div className='ordered-products-container'>
                            {productsElems}
                        </div>
                        <div className='order-total-container'>
                            <h3>Total:&nbsp;&nbsp;रू&nbsp;{(orderData.totalPrice).toFixed(2)}</h3>
                            <div className='price-details-icon'>
                                <RxQuestionMarkCircled />
                                <p>includes delivery fee and promotions</p>
                            </div>
                            <h3>{orderData.paymentMode}</h3>
                        </div>
                    </div>
                    <div className='delivery-div'>
                        <div className='delivery-address-div'>
                            <h4>{orderData.address.title}. {orderData.address.fullName}</h4>
                            <p>{orderData.address.street}</p>
                            <p>{orderData.address.city}, {orderData.address.district}</p>
                            <p>Phone number: {orderData.address.phone}</p>
                            <p>Email: <a href={`mailto:${orderData.email}`} target='_blank' aria-label='email'>{orderData.email}</a></p>
                        </div>
                        <Form action='/orders' method='post' replace preventScrollReset
                            className={`${state === 'submitting' ? 'disable' : ''}`}>
                            <input type='text' name='orderData' value={JSON.stringify(orderData)} readOnly />
                            <input type="date" name='delivery-date' min={(new Date()).toLocaleDateString('en-CA')} required />
                            {orderData.emailed ? <button type='button' disabled>Email Sent</button>
                                : <button type='submit' name='intent' value='email'>
                                    {state !== 'idle' && formData?.get('intent') === 'email' ? <>Sending<Submitting /></> : 'Send Email'}
                                </button>}
                        </Form>
                    </div></> : <h2>No order found</h2>}
            </div>
        </div>
    )
}

function ProductCard({ productData }) {
    return (
        <div className='ordered-product-container'>
            <img src={productData.productImg} alt={`${productData.title} image`} />
            <h3>{productData.title}</h3>
            <h4>{productData.size}</h4>
            <p>रू&nbsp;{(productData.price).toFixed(2)}</p>
            <p>Quantity: {productData.quantity}</p>
        </div>
    )
}
