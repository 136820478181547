import React, { useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import Filter from '../Components/Filter/Filter'

export default function Piechart({ products, categories, productIds }) {

    const [selected, setSelected] = useState('')

    let filteredData = Object.keys(categories).map(category =>
        ({ name: category, quantity: categories[category].reduce((acc, id) => acc += productIds[id]?.quantity || 0, 0) }))

    if (selected) filteredData = categories[selected].map(id => {
        if (productIds[id]) return {
            name: `${productIds[id].title} ${productIds[id].size}`,
            quantity: productIds[id].quantity
        }
        else return { name: id, quantity: 0 }
    })

    const notSold = filteredData.every(data => data.quantity === 0)

    return (
        <div className='piechart-container'>
            <Filter productList={products} selected={selected} setSelected={setSelected} classname='pie-filter' />
            {notSold ? <div className='not-sold'>0 Sold</div> :
                <ResponsiveContainer width='100%' style={{ flexGrow: 1 }}>
                    <PieChart>
                        <Pie data={filteredData} dataKey='quantity' cx='50%' cy='50%' label outerRadius='70%' fill='#000'
                            innerRadius='30%' style={{ outline: 'none' }} animationDuration={1000} animationBegin={0}
                            activeShape={{ fill: '#353535' }} />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>}
        </div>
    )
}

function CustomTooltip({ active, payload }) {
    if (active && payload && payload.length) {
        return (
            <div className="tooltip">
                <p>{payload[0]?.payload?.name}&nbsp;:&nbsp;{payload[0]?.payload?.quantity}</p>
            </div>
        )
    }
    return null
}