import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'
import { IoCalendar } from "react-icons/io5"
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DatePicker.css'

export default function DatePicker({ range, setRange }) {

    const [open, setOpen] = useState(false)
    const ref = useRef()

    useEffect(() => {
        function handleClick(event) {
            if (!ref.current.contains(event.target)) setOpen(false)
        }
        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [])

    const options = { day: 'numeric', month: 'numeric', year: 'numeric' }

    return (
        <div className='calendarWrap' ref={ref}>

            <p onClick={() => setOpen(!open)} className={open ? 'focus' : ''}><IoCalendar className='calendar-icon' />
                {`${range[0].startDate.toLocaleDateString('en-IN', options)} to ${range[0].endDate.toLocaleDateString('en-IN', options)}`}
            </p>
            {open && <DateRange editableDateInputs onChange={item => setRange([item.selection])} ranges={range}
                moveRangeOnFirstSelection={false} rangeColors={['#000']} months={1} minDate={new Date('2024-05-01')}
                direction='horizontal' className='calendarElement' dateDisplayFormat='d MMM yyyy' maxDate={new Date()} />}
        </div>
    )
}
