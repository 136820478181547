import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, Outlet, ScrollRestoration, redirect, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth, queryClient } from '../Db/FirebaseConfig'
import { CurrentUser } from '../Utils/HandleUser'
import { IoMenu, IoClose } from "react-icons/io5"
import Logo from '../Img/Logo'
import useMedia from '../Utils/Media'
import './Nav.css'

export async function loader() {
    const user = await CurrentUser()
    if (!user) throw redirect('/login')
    return null
}

export default function Nav() {

    const isMobile = useMedia('screen and (max-width: 600px)')
    const [switcher, setSwitcher] = useState(false)
    const navigate = useNavigate()
    const ref = useRef([])

    useEffect(() => {
        function checkMenu(event) {
            if (!ref.current[0].contains(event.target) && switcher) menuSwitch()
        }
        if (isMobile) window.addEventListener('click', checkMenu)
        else window.removeEventListener('click', checkMenu)
        return () => window.removeEventListener('click', checkMenu)
    }, [isMobile, switcher])

    function menuSwitch() {
        setSwitcher(!switcher)
        ref.current[1].classList.toggle('open')
    }

    async function signout() {
        await queryClient.invalidateQueries({ queryKey: ['ordersData'] })
        await queryClient.invalidateQueries({ queryKey: ['productsData'] })
        await queryClient.invalidateQueries({ queryKey: ['promotionsData'] })
        queryClient.removeQueries({ queryKey: 'days' })
        queryClient.removeQueries({ queryKey: 'range' })
        await signOut(auth)
        navigate('/login')
    }

    return (
        <>
            <div className='wrapper-nav'>
                <nav className='navigation'>
                    <Link to='/' className='logo-link' aria-label='home'><Logo /></Link>
                    {isMobile && <span className='menu-span' onClick={menuSwitch} ref={el => ref.current[0] = el}>
                        {switcher ? <IoClose className='menu-icon' /> : <IoMenu className='menu-icon' />}</span>}
                    <div className='pages' ref={el => ref.current[1] = el}>
                        <NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
                        <NavLink to='orders' className={({ isActive }) => isActive ? 'active' : ''}>Orders</NavLink>
                        <NavLink to='products' className={({ isActive }) => isActive ? 'active' : ''}>Products</NavLink>
                        <NavLink to='promotions' className={({ isActive }) => isActive ? 'active' : ''}>Promotions</NavLink>
                    </div>
                    <button type='button' onClick={signout}>Log Out</button>
                </nav>
            </div>
            <ScrollRestoration getKey={location => location.pathname} />
            <Outlet />
        </>
    )
}
